import React from 'react';
import ReactDOM from 'react-dom/client';
import './Post.css';

function Post(props) {

    const [isHovered, setIsHovered] = React.useState(false);

    return (
        <div className={`Post ${isHovered ? 'PostHovered' : ''}`} 
        onMouseEnter={()=>{setIsHovered(true)}} 
        onMouseLeave={()=>setIsHovered(false)}
        onClick={()=>props.openArticle(props.post)}>
            <div className="postImage"><img src={props.img} alt="Błąd ładowania obrazu." /></div>
            <div className="postPreview">
                <div className='postTittle'>{props.tittle}</div>
                <div>{props.postPreview ? props.postPreview : "Błąd ładowania podglądu."}</div>
            </div>
        </div>
    )
}

export default Post;
