import React from 'react';
import ReactDOM from 'react-dom/client';
import './Sides.css';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

function ContactSide() {

    const position = { lat: 53.54, lng: 10 };
    const myApiKey = "AIzaSyAAbUp5lrzQbXekfRc7PpKchiBNsoNh22k";

    return (
        <div id='ContactSide'>
            <div id='Map'>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2319.108922931744!2d17.019514777022355!3d54.46101399074068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fe1b7844dc1e8f%3A0xee18f866aba437ba!2sIn%C5%BCynieria%20dla%20Domu!5e0!3m2!1spl!2spl!4v1729349212902!5m2!1spl!2spl"
                    allowfullscreen
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
            <div id='ContactData'>
                <p><b>Adres: </b> ul. Jana Padareskiego 5/10, 76-200 Słupsk</p>
                <p><b>Godziny otwarcia: </b> poniedziałek - piątek 9.00-16.00</p>
                <p><b>Numer telefonu: </b> +48 786 917 647</p>
                <p><b>Adres e-mail: </b> biuro@idladomu.pl</p>
                <p><b>Social media: </b> <a href='https://www.facebook.com/profile.php?id=61553730187757' target='_blank'>Facebook</a></p>
            </div>
        </div>
    )
}

export default ContactSide;