import React from 'react';
import ReactDOM from 'react-dom/client';
import './Sides.css';
import VerticalArticle from './VerticalArticle';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import img from './Images/abaoutusimg.jpg'

function AboutUsSide() {

    return (
        <div id='AboutUsSide'>
            <div class="AbaoutUsSideImg"><img src={img} alt="Błąd ładowania obrazu." /></div>
            <div class="AbaoutUsSideText">
                <div class="AbaoutUsSideTextMain">
                    <p>Ukończyłem Wydział Inżynierii Mechanicznej i Mechatroniki Zachodniopomorskiego Uniwersytetu Technologicznego w Szczecinie na kierunku Energetyka oraz studia podyplomowe w kierunku Certyfikacji i Auditingu Energetycznego. Jestem członkiem Zrzeszenia Audytorów Energetycznych oraz zostałem wpisany do wykazu osób uprawnionych do wyznaczania charakterystyki energetycznej budynków, prowadzonego przez Ministerstwo Rozwoju i Technologii.</p>
                    <p>Moją specjalizacją jest optymalizacja zużycia energii w różnorodnych obszarach, zarówno w sektorze budowlanym jak i technologicznym, w szczególności wszędzie tam, gdzie realizowane są różnego rodzaju przemiany termodynamiczne.</p>
                    <p>Jestem także absolwentem Wydziału Elektroniki i Informatyki Politechniki Koszalińskiej oraz słuchaczem studiów podyplomowych z zakresu Inżynierii Danych prowadzonych na Wydziale Fizyki Technicznej i Matematyki Stosowanej Politechniki Gdańskiej. Hobbystycznie zajmuje się programowaniem, układami elektronicznymi, uczeniem maszynowym i analizą danych.</p>
                    <p>W swojej pracy wykorzystuje wiedzę interdyscyplinarną z wielu dziedzin nauki i techniki. Szeroki zakres przygotowania merytorycznego ułatwia mi wykonywanie kompleksowych, złożonych analiz oraz symulacji energetycznych. Korzystając ze współczesnej technologii jestem w stanie pozyskać kluczowe dane w trakcie przeprowadzanych przeze mnie pomiarów.</p>
                    <p>Zapraszam Państwa do zapoznania się z moją ofertą.</p>
                    <p>Z poważaniem,</p>
                </div>
                <div class="AbaoutUsSideTextFooter">
                    <br></br>
                    <p>mgr inż. Oskar Gumowski</p>
                    <br></br>
                    <p>Audytor Energetyczny ZAE nr 2792</p>
                    <p>Certyfikator Energetyczny wpis do CHEB nr 17048</p>
                </div>
            </div>
        </div>
    )
}

export default AboutUsSide;