import React from 'react';
import ReactDOM from 'react-dom/client';
import './VerticalArticle.css';


function VerticalArticle(props){

    return(
        <div class="VerticalArticle">
        <div class="TextField"> fdff</div>
        <div class="ImgField">
            <div class="imgInField">ddd</div>
            <div class="imgInField">dddd</div>
            <div class="imgInField">dddd</div>
            <div class="imgInField">dddd</div>
            <div class="imgInField">dddd</div>
            <div class="imgInField">dddd</div>
        </div>
        </div>
    )
}

export default VerticalArticle;