import img1 from './Images/post1.jpg';
import img2 from './Images/post2.jpg';
import img3 from './Images/post3.jpg';
import img4 from './Images/post4.jpg';
import img5 from './Images/post5.jpg';
import img6 from './Images/post6.jpg';

import AuditIco from './Images/AuditIco.png';
import SCHEIco from './Images/SCHEIco.png';
import OZCIco from './Images/OZCIco.png';
import OZEIco from './Images/OZEIco.png';
import LAFIco from './Images/LAFIco.png';
import CarbonIco from './Images/CarbonIco.png';
import ThermoIco from './Images/ThermoIco.png';


function post(tittle, text, postPreview, img){
    this.tittle = tittle;
    this.text = text.split('\n');
    this.postPreview = postPreview;
    this.img = img;
}

let blogs = [];
let services=[];

// Blogi

blogs.push(new post("LiDAR w służbie efektyności energetycznej", "Technologia LiDAR zrewolucjonizowała techniki pozyskiwania danych do tworzenia Numerycznego Modelu Terenu. Pomiar wykonywany za pomocą światła laserowego emitowanego z samolotu wykrywa jednak nie tylko ukształtowanie powierzchni Ziemi ale także pozostałe obiekty w przestrzeni, takie jak drzewa i budynki, które są następnie klasyfikowane przez wyspecjalizowane algorytmy analizy danych. \nGłówny Urząd Geodezji i Kartografii udostępnia te dane publicznie, dzięki czemu możliwe jest analizowanie wysokości budynków w oprogramowaniu przetwarzającym dane typu .LAZ. Na zdjęciu obok przedstawiono badanie geometrii budynku jednorodzinnego przy pomocy współczesnej technologii laserowej.", "Przykład zastosowania technologi LiDAR w analizie geometrii budynku.", img6));
blogs.push(new post("Kredyt Ekologiczny", "Rozpoczął się trzeci nabór składania wniosków o dotacje Kredyt Ekologiczny Banku Gospodarstwa Krajowego. Tym razem dotacja dla przedsiębiorstw z sektora MŚP wynosi od 2 do 50 mln zł, z poziomem dofinansowania od 15 do 80% przedsięwzięcia w zależności od rodzaju wydatku, wielkości przedsiębiorstwa oraz lokalizacji inwestycji. Dotacje można uzyskać na poprawę efektywności energetycznej, w tym termomodernizacje budynków, modernizacje maszyn, urządzeń, ciągów technologicznych, czy inwestycje w odnawialne źródła energii. Nabór wniosków potrwa do 31 stycznia 2025 r. \nZachęcam Państwa do skorzystania z moich usług w zakresie przygotowania audytu przedsięwzięcia termomodernizacyjnego lub audytu efektywności energetycznej.", "Rusza trzeci nabór wniosków o Kredyt Ekologiczny. Dotacja do porpawy efektywności energetycznej dla przedsiębiorstw do 50 mln zł.", img5));
blogs.push(new post("Zmiany w Programie Czyste Powietrze", "Od 14 czerwca wprowadzono zmiany w Programie Czyste Powietrze. Między innymi ograniczono dotację dla kotłów na biomasę i pomp ciepła tylko dla tych urządzeń, które znajdują się na liście zielonych urządzeń i materiałów (ZUM). Wprowadzono również obowiązek wykonania Audytu Energetycznego, celem dobrania odpowiedniej pompy ciepła do ogrzewania.\nDobór pompy ciepła powinien być poprzedzony analizą start energetycznych w budynku. Praca wykonywana przez pompę ciepła, a tym samym pobierany przez nią prąd, a więc i koszty eksploatacji, będzie tym mniejsza im lepiej zaizolowany będzie budynek. Równocześnie sprawność pomp rośnie gdy temperatura górnego ciepła jest niższa, co w praktyce oznacza, że pompa ciepła pobiera mniej prądu w przypadku zasilania niskotemperaturowych odbiorników ciepła, np. instalacji ogrzewania podłogowego.\nRównocześnie przy doborze odpowiedniej mocy pompy ciepła powietrze/woda powinny zostać uwzględnione warunki klimatyczne panujące w rejonie inwestycji, w szczególności moc pompy ciepła typu powietrze/woda  powinna zostać dobrana do temperatury punktu biwalentnego, a instalacja powinna zostać wyposażona w zapasowe szczytowe źródło ciepła.\nPolecam Państwu swoje usługi w doborze mocy urządzeń grzewczych oraz mocy odbiorników ciepła (grzejników).", "Od 14 czerwca zmiany w Programie Czyste Powietrze, między innymi obowiązkowy audyt do doboru Pompy Ciepła.", img4));
blogs.push(new post("Badania termograficzne", "Badania termograficzne (termowizja) to specyficzny rodzaj ekspertyzy obrazujący przestrzenny rozkład temperatury. Dzięki analizie budynku z wykorzystaniem kamery termowizyjnej możliwe jest zlokalizowanie tzw. mostków termicznych w konstrukcji obiektu. Mostki termiczne są obszarami konstrukcji o niskim oporze przewodzenia ciepła, co prowadzi do dużych strat energii, zawilgocenia wnętrz i powstawaniu pleśni. Dzięki badaniom termograficznym możliwe jest m.in. zlokalizowanie wad i sterek budowlanych lub wykrycie lokalizacji wycieku z instalacji ogrzewania podłogowego.\n Zachęcam Państwa do skorzystania z moich usług w zakresie badań termowizyjnych.", "Zastosowanie termowizji w analizie energetycznej budynku.", img3));
blogs.push(new post("Cyfrowy model budynku", "BIM to cyfrowa technologia umożliwiająca trójwymiarowe modelowanie budynku z uwzględnieniem jego właściwości fizycznych i funkcjonalnych, stosowana w różnych dziedzinach architektury i inżynierii.\nW świetle dyrektyw unijnych dotyczących poprawy charakterystyki energetycznej budynków i wynikających z nich programów rządowych wspierających termomodernizacje, borykamy się z remontem obiektów często pozbawionych dokumentacji technicznej. BIM jest technologią umożliwiającą jej odtworzenie, równolegle pozwalającą na budowę modelu parametrycznego stanowiącego dane wsadowe do obliczeń cieplnych.\nNa zdjęciu po lewej widać cyfrowy model domu jednorodzinnego.", "Parametryczne, trójwymiarowe modele budynków.", img2));
blogs.push(new post("Premia termomodernizacyjna i remontowa", "Program TERMO to program dofinansowania do termomodernizacji skierowany do osób fizycznych, wspólnot i spółdzielni mieszkaniowych oraz jednostek samorządu terytorialnego. W ramach programu przyznawane są różnorodne formy wsparcia w postaci grantów i premii. Premia termomodernizacyjna obniża kwotę kredytu zaciągniętego na potrzeby termomodernizacji od 26% do 31% kosztów całkowitych przedsięwzięcia. Z premii mogą skorzystać m.in. wspólnoty i spółdzielnie mieszkaniowe, towarzystwa budownictwa społecznego i właściciele domków jednorodzinnych. \nNa cele remontu budynku wykonam dla Państwa niezbędne pomiary, zaproponuje możliwości optymalizadji zużycia energii i redukcji jej kosztów oraz przygotuje audyt energetyczny niezbędny do pozyskania premii termomodernizacyjnej.\nZachęcam Państwa do skorzystania z mojej oferty.", "Dofinansowanie do termomodernizacji budynków wielorodzinnych.", img1));



// Usługi
services.push(new post("Audyt Energetyczny Przedsiębiorstwa", "Ustwa z dnia 20 maja 2016 r. o efektywności energetycznej nakłada na dużych przedsiębiorców obowiązek wykonania co 4 lata audytu energetycznego przedsiębiorstwa lub zlecenia jego przeprowadzenia.\nEkspertyza ta obejmuje w swoim zakresie analizę pofilu zużycia energii i możliwości optymalizacji tego zużycia, we wszystkich energochłonnych obszarach przedsiębiorstwa, tj. budynkach, instalacjach, urządzeniach oraz w transporcie.\nPolecam Państwu swoje usługi w celu przeprowadzenia audytu energetcyznego państwa przedsiębiorstwa.","Przeprowadzam audyty energetyczne dużych przedsiębiorstw, zgodnie z wymaganiami Ustawy o Efektywności Energetycznej.", CarbonIco));
services.push(new post("Audyt Energetyczny i Remontowy oraz Audyt Efektywności Energetycznej", "Audyt energetyczny jest to opracowanie techniczne określające profil zużycia energii w budynku, rozwiązania techniczne mające na celu ograniczenie tego zużycia, a także ekonomiczne uzasadnienie wskazanych prac. Audyt energetyczny zawiera wstępne założenia do projektu budowlanego, określa zużycie energii oraz emisję dwutlenku węgla w stanie istniejącym oraz redukcję tych parametrów na wskutek przeprowadzenia wskazanych prac remontowych.\nAudyt Remontowy jest specyficzną formą Audytu Energetycznego, którego zakres obejmuje równiez dodatkowe prace remontowe inne niż prace termomodernizacyjne.\nAudyt efektywności energetycznej jest z kolei opracowaniem podobnym do audytu energetycznego, obejmującym jednak swym zakresem obiekt dowolnego przeznaczenia, np. proces technologiczny, instalacje techniczną, urządzenia oraz maszyny.\nAudyty energetyczne są często wymagane celem pozyskania dofinansowania do termomodernizacji lub poprawy efektywności energetycznej w państwowych programach wsparcia takich jak Program Czyste Powietrze, Program TERMO, Kredyt Ekologiczny.", "Polecam Państwu swoje usługi w zakresie przeprowadzania audytów energetycznych i audytów efektywności energetycznej, na potrzeby projektów budowlanych i pozyskania dofinansowania do termomodernizacji lub poprawy efektywności energetycznej.", AuditIco));
services.push(new post("Charakterystyka Energetyczna", "Opracowanie określające wielkość zapotrzebowania na energię dla budynku projektowanego lub w stanie istniejącym. Projektowa Charakterystyka Energetyczna jest potrzebna do uzyskania pozwolenia na budowę, podczas gdy Świadectwo Charakterystyki Energetycznej jest dokumentem wymaganym do uzyskania pozwolenia na użytkowanie budynku, a także podczas sprzedaży bądź najmie nieruchomości, obniżenia oprocentowania na kredy hipoteczny i w celu uzyskania dofinansowania do pompy ciepła w programie Moje Ciepło.", "Wyznaczam charakterystykę energetyczną budynków do celów projektowych oraz na potrzeby systemu Świadectw Charakterystyki Energetycznej.", SCHEIco));
services.push(new post("Obliczenia OZC", "Ekspertyza określające zapotrzebowanie na moc grzewczą budynku, umożliwiająca dobór mocy źródła ciepła (np. kotła lub pompy ciepła) oraz moc odbiorników ciepła (grzejników) w poszczególnych pomieszczeniach. Obliczenia OZC wykonywane są wg normy PN-EN 12831.\nPolecam Państwu swoje usługi w zakresie doboru urządzeń grzewczych i odbiorników ciepła. W przypadku doboru urządzeń takich jak gruntowe pompy ciepła szacuje również wymaganą ilość odwiertów gruntowych i długość wymienników ciepła.", "Wyznaczam obciążenie cieplne pomieszczeń na potrzeby doboru grzejników i urządzeń grzewczych zgodnie z normą PN-EN 12831.", OZCIco));
services.push(new post("Dobór mocy OZE", "Korzystając z aktualnych danych meteorologicznych jestem w stanie oszacować uzysk z instalacji fotowoltaicznych w rejonie Państwa inwestycji. Znając zapotrzebowanie na energię elektryczną obiektu dobieram moc projektowanej instalacji fotowoltaicznej.\nWykonuje także pozostałe obliczenia związane z Odnawialnymi Źródłami Energii, m.in. szacowanie uzysku energetycznego z turbin wiatowych oraz wymiarowanie instalacji kolektorów słonecznych, tj. urządzeń przekształacających energię słoneczną w energię cieplną, wykorzystywaną na cele ciepłej wody użytkowej.", "Pomagam zwymiarować moc projektownych instalacji solarnych oraz turbin wiatrowych do zapotrzebowania danego obiektu.", OZEIco));
services.push(new post("Badania termograficzne", "Opracowanie będące wynikiem badania budynku z użyciem kamery termowizyjnej, umożliwiającego zlokalizowanie mostków termicznych i ocenę jakości istniejącej izolacji cieplnej.\nDzięki badaniom z wykorzystaniem kamery termowizyjnej możliwe jest zlokalizowanie wad i usterek budowlanych, lokalizacja pęknięć w instalacjach ogrzewania podłogowego oraz inspekcja robót dociepleniowych.", "Wykonuje badania termowizyjne i opracowuje raporty zgodnie z PN-EN 13187.", ThermoIco));
services.push(new post("Moc szczytowa i współczynniki wyrównawcze LAF", "Wykonuje obliczenia zapotrzebowania na moc szczytową w budynkach wielorodzinnych i obliczam współczynniki korekcyjne LAF niezbędne do sprawiedliwego rozdziału kosztów za ogrzewanie pomiędzy lokale wyposażone w podzielniki ciepła.", "Wykonuje obliczenia współczynników LAF umożliwiających sprawiedliwy podział kosztów między lokatorów zamieszkujących budynki wielorodzinne.", LAFIco));
//services.push(new post("Ślad węglowy", "Opis", "Obliczamy ślad węglowy przedsiębiorstw.", CarbonIco));

export {blogs, services};



